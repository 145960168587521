.footer {
  width: 100vw;
  height: auto;
  min-height: 12rem;
  padding: 2rem 0;
  bottom: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-around;
  color: white;
  background-color: black;
}

.footer-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
}

.footer-column h5 {
  text-decoration: underline;
  letter-spacing: 4px;
  font-size: 1.5rem;
}

.footer-column p {
  font-size: 1.2rem;
}


@media (max-width: 900px)  {
  .footer {
    flex-direction: column;
    align-items: center;
  }
}