.small-screen {
  padding: 8rem 2rem 4rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.custom-build {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.build-header {
  padding-top: 8rem;
  text-align: center;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(147deg, #c3cbdc 0%, #edf1f4 74%);
}

.custom-build p {
  margin-bottom: 4rem;
}
.builder {
  width: 100vw;
  height: 400px;
  background-image: linear-gradient(315deg, #c2b6b6 0%, #576574 74%);
  display: flex;
  align-items: center;
  justify-content: center;
}

.drop-zone {
  background-color: #fdfdfd;
  display: flex; 
  flex-direction: row;
  align-items: center;
  justify-content: center;
  overflow-x: scroll;
  overflow-y: hidden;
  height: 80%;
  width: 80%;
}

.selector {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* padding-left: 40px; */
  /* overflow-x: scroll; */
  width: 100vw;
  height: auto;
  min-height: 250px;
  background-color: #d4d4d4;
  background-image: linear-gradient(147deg, #c3cbdc 0%, #edf1f4 74%);
  border-top: 1px solid #646464;
}

.selector p {
  margin-bottom: 5px;
  height: 2rem;
}
.selector h3 {
  margin-left: 20px;
}

.selector-list {
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  width: 95%;
  padding-left: 40px;
}

.dropped-item {
  display: flex;
  position: relative;
}

.dropped-item img {
  height: 300px;
  width: auto;
}

.item img {
  height: 150px;
  width: auto;
}

.remove  {
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 25%;
  border: 1px solid #646464;
}

.remove img {
  height: 10px;
  width: auto;
}

@media (max-width: 900px)  {
  .drop-zone {
    justify-content: flex-start;
  }
}
