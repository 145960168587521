.spaw {
  height: auto;
  width: 100%;
  padding-top: 6rem;
  display: flex;
  justify-content: space-evenly;
  background-image: linear-gradient(315deg, #c2b6b6 0%, #576574 74%);
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  flex-basis: auto;
}

.spaw h3 {
  font-size: 2.5rem;
  font-weight: 300;
  width:100%;
  text-align: center;
  color: #baf7ff;
}

.spaw h5 {
  margin: 0 14rem;
  font-size: 1.2rem;
  font-weight: 200;
  width:100%;
  text-align: center;
  color: #baf7ff;
}

@media (max-width: 900px)  {
  .spaw h5 {
    margin: 0 4rem;
  }
}


.spaw a {
  width: 100%;
  text-align: center;
  color: #baf7ff;
  /* text-decoration: none; */
  margin-bottom: 4rem;
  font-size: 1.4rem;
  font-weight: 200;
}

.spaw div {
  height: auto;
  width: auto;
  background-color: rgba(255,255,255, .9);
  border-radius: 3px;
  margin: 4rem;
}

.spaw div:before {
  box-shadow: inset 0 0 2000px rgba(255, 255, 255, .5);
  filter: blur(10px);
}

.spaw div img {
  height: 50vh;
  width: auto;
}

@media (max-width: 400px)  {
  .spaw div img {
    height: auto;
    width: 90vw;
  }
 }