.brochure {
  height: calc(100vh - 80px);
  width: 100vw;
  margin-top: 5rem;
  display: flex; 
  flex-direction: column;
  background-image: url('./Brochure.jpg');
  background-position: center;
  background-repeat: no-repeat;

  background-size: contain;
}

.download {
  position: absolute;
  top: 8rem;
  right: 80px;
  cursor: pointer;
}

@media (max-width: 900px)  {
  .download {
    top: 6rem;
    right: 1rem;
  }
}