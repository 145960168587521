.landing {
  padding-top: 50px;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background-image: url("./images/so-white.png");
  background-position: center; */
  background-color: #c3cbdc;
  background-image: linear-gradient(147deg, #c3cbdc 0%, #edf1f4 74%);

}

.landing:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  box-shadow: inset 0 0 2000px rgba(255, 255, 255, .5);
    filter: blur(10px);
}

.landing img {
  height: 70vh;
  width: auto;
  overflow-x: none;
}

@media (max-width: 900px)  {
  .landing img {
    height: auto;
    width: 90vw;
  }
}