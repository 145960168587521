.contact {
  padding-top:8rem;
  min-height: 100vh;
  height: auto;
  width: 100%;
  max-width: 100vw;
  background-color: #d9d9d9;
  background-image: linear-gradient(315deg, #d9d9d9 0%, #f6f2f2 74%);
}

.contact iframe {
  padding: 2rem 10rem 2rem;

}

@media (max-width: 900px)  {
  .contact {
    padding: 8rem 1rem 1rem;
  }
  .contact iframe{
    padding: 0;
  }
 }